import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Navbar,
  Alignment,
  AnchorButton,
  NavbarDivider,
  EditableText,
  Popover,
} from '@blueprintjs/core';

import FaGithub from '@meronex/icons/fa/FaGithub';
import FaDiscord from '@meronex/icons/fa/FaDiscord';
import FaTwitter from '@meronex/icons/fa/FaTwitter';
import BiCodeBlock from '@meronex/icons/bi/BiCodeBlock';
import MdcCloudAlert from '@meronex/icons/mdc/MdcCloudAlert';
import MdcCloudCheck from '@meronex/icons/mdc/MdcCloudCheck';
import MdcCloudSync from '@meronex/icons/mdc/MdcCloudSync';
import styled from 'polotno/utils/styled';
import { Logo } from './logo'

import { useProject } from '../project';

import { FileMenu } from './file-menu';
import { DownloadButton } from './download-button';
import { UserMenu } from './user-menu';

const NavbarContainer = styled('div')`
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

const TittleTextArea = styled('div')`
  @media (max-width:920px) {
      max-width: 45vw !important;
      width: 45vw;
    }

  @media (max-width:800px) {
   max-width: 32vw !important;
    }
`;
export default observer(({ store }) => {
  const project = useProject();

  return (
    <NavbarContainer className="bp5-navbar">
      <NavInner>
        <Navbar.Group align={Alignment.LEFT}>
          <Logo />
          <NavbarDivider />
          <FileMenu store={store} project={project} />
          <TittleTextArea style={{
            paddingLeft: '20px',

            color: "#000000",
            fontFamily: "'Secular One', sans-serif",
            textAlign: "center",
            maxWidth: "600px",
            fontSize: "15px"
          }}>
            <EditableText
              value={window.project.name}
              placeholder="Design name"
              onChange={(name) => {
                window.project.name = name;
                window.project.requestSave();
              }}


            />
          </TittleTextArea>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
    
          <NavbarDivider />
          <DownloadButton store={store} />
          {/* <NavbarHeading>isekaisoft Photo Editor</NavbarHeading> */}
        </Navbar.Group>
      </NavInner>
    </NavbarContainer>
  );
});
